import React from 'react';
import StepForm from './StepForm';
import 'bootstrap/dist/css/bootstrap.min.css';

const AppB = () => {
  return (
    <div className='parent-div'>
      <StepForm />
    </div>
  );
};

export default AppB;
