import React, { useEffect, useState } from 'react';
import compassBackground from "./new-plot-img.webp";

const Compass = ({ showValue, selectedValue, setDirectionInForm }) => {
  const [direction, setDirection] = useState("N");
  useEffect(() => {
    console.log("showValue", showValue, "selectedValue", selectedValue);
    setDirection(selectedValue);
  }, [selectedValue]);

  const handleDirectionChange = (newDirection) => {
    // setDirection(newDirection);
    setDirectionInForm({
      target: { name: "direction", value: newDirection, type: "compass" },
    });
  };
  return (
    <div className="compass-container mt-4">
      {showValue == true ? (
        <div className="compass-div compass-container">
          <img
            className={`compass-img direction-${direction}`}
            src="https://mailer-assets.makemyhouse.com/form-type-images/compass.svg"
            alt="Compass"
          />
          <img
            src="https://mailer-assets.makemyhouse.com/form-type-images/triangle.svg"
            alt="triangle"
          />
        </div>
      ) : (
        <div className="compass-div-img">
          <div className="compass-div-bg compass-container">
            <img
              className={`compass-false-img direction-${direction}`}
              src="https://mailer-assets.makemyhouse.com/form-type-images/compass.svg"
              alt="Compass"
            />
            <img
              src="https://mailer-assets.makemyhouse.com/form-type-images/triangle.svg"
              alt="triangle"
            />
          </div>
        </div>
      )}
      {showValue == true ? (
        <div className="radio-buttons ">
          <div className="custom-radio-extra-div">
            <div
              className={`radio-option-extra radio-option-extra-name ${
                // formDataPart.SiteDetails[fieldName] === option ? "active" : ""
                direction === "N" ? "active" : ""
              }`}
            >
              <label className="compass-ratio-input" style={{ width: "60px" }}>
                <input
                  type="radio"
                  name="direction"
                  value="N"
                  checked={direction === "N"}
                  onChange={() => {
                    handleDirectionChange("N");
                  }}
                />
                N
              </label>
            </div>
          </div>

          <div className="custom-radio-extra-div">
            <div
              className={`radio-option-extra radio-option-extra-name ${
                // formDataPart.SiteDetails[fieldName] === option ? "active" : ""
                direction === "NE" ? "active" : ""
              }`}
            >
              <label className="compass-ratio-input">
                {" "}
                <input
                  type="radio"
                  name="direction"
                  value="NE"
                  checked={direction === "NE"}
                  onChange={() => handleDirectionChange("NE")}
                />
                NE
              </label>
            </div>
          </div>
          <div className="custom-radio-extra-div">
            <div
              className={`radio-option-extra radio-option-extra-name ${
                // formDataPart.SiteDetails[fieldName] === option ? "active" : ""
                direction === "E" ? "active" : ""
              }`}
            >
              <label className="compass-ratio-input">
                {" "}
                <input
                  type="radio"
                  name="direction"
                  value="E"
                  checked={direction === "E"}
                  onChange={() => handleDirectionChange("E")}
                />
                E
              </label>
            </div>
          </div>
          <div className="custom-radio-extra-div">
            <div
              className={`radio-option-extra radio-option-extra-name ${
                // formDataPart.SiteDetails[fieldName] === option ? "active" : ""
                direction === "SE" ? "active" : ""
              }`}
            >
              <label className="compass-ratio-input">
                {" "}
                <input
                  type="radio"
                  name="direction"
                  value="SE"
                  checked={direction === "SE"}
                  onChange={() => handleDirectionChange("SE")}
                />
                SE
              </label>
            </div>
          </div>
          <div className="custom-radio-extra-div">
            <div
              className={`radio-option-extra radio-option-extra-name ${
                // formDataPart.SiteDetails[fieldName] === option ? "active" : ""
                direction === "S" ? "active" : ""
              }`}
            >
              <label className="compass-ratio-input">
                {" "}
                <input
                  type="radio"
                  name="direction"
                  value="S"
                  checked={direction === "S"}
                  onChange={() => handleDirectionChange("S")}
                />
                S
              </label>
            </div>
          </div>
          <div className="custom-radio-extra-div">
            <div
              className={`radio-option-extra radio-option-extra-name ${
                // formDataPart.SiteDetails[fieldName] === option ? "active" : ""
                direction === "SW" ? "active" : ""
              }`}
            >
              <label className="compass-ratio-input">
                {" "}
                <input
                  type="radio"
                  name="direction"
                  value="SW"
                  checked={direction === "SW"}
                  onChange={() => handleDirectionChange("SW")}
                />
                SW
              </label>
            </div>
          </div>
          <div className="custom-radio-extra-div">
            <div
              className={`radio-option-extra radio-option-extra-name ${
                // formDataPart.SiteDetails[fieldName] === option ? "active" : ""
                direction === "W" ? "active" : ""
              }`}
            >
              <label className="compass-ratio-input">
                {" "}
                <input
                  type="radio"
                  name="direction"
                  value="W"
                  checked={direction === "W"}
                  onChange={() => handleDirectionChange("W")}
                />
                W
              </label>
            </div>
          </div>
          <div className="custom-radio-extra-div">
            <div
              className={`radio-option-extra radio-option-extra-name ${
                // formDataPart.SiteDetails[fieldName] === option ? "active" : ""
                direction === "NW" ? "active" : ""
              }`}
            >
              <label className="compass-ratio-input">
                {" "}
                <input
                  type="radio"
                  name="direction"
                  value="NW"
                  checked={direction === "NW"}
                  onChange={() => handleDirectionChange("NW")}
                />
                NW
              </label>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <style>{`
      .compass-div{
            background-color: whitesmoke;
            padding:0.5rem;      }

            .compass-div-img{
               background-color: whitesmoke;
            padding:0.2rem; 
            }
            .compass-div-bg {
  background-image: url(${compassBackground});
  background-size: cover; 
 padding: 1rem;
    width: 190px;
    min-height: 200px;
}
        .compass-container {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
.radio-buttons label {
    margin-top: 5px !important; 
    margin-right: 10px;
}
.compass-false-img{
  padding:0.4rem;
   width: 142px; /* Adjust the size as needed */
          transition: transform 0.5s ease-in-out;
}
        .compass-img {
          width: 200px; /* Adjust the size as needed */
          transition: transform 0.5s ease-in-out;
        }
.compass-ratio-input > input{
display: none; 
}

      .direction-S { transform: rotate(0deg); }
      .direction-SE { transform: rotate(45deg); }
      .direction-E { transform: rotate(90deg); }
      .direction-NE { transform: rotate(135deg); }
      .direction-N { transform: rotate(180deg); }
      .direction-NW { transform: rotate(225deg); }
      .direction-W { transform: rotate(270deg); }
      .direction-SW { transform: rotate(315deg); }

        .radio-buttons {
          margin-top: 20px;
          display: flex;
    flex-wrap: wrap;
        }

        .radio-buttons label {
          margin-top: 50px;
          margin-right: 10px;
        }
      `}</style>
    </div>
  );
};

export default Compass;
