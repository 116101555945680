import React, { useEffect, useState } from "react";
import abc from "./paymentfailed.png";
import "./App.css";

function PaymentFailed() {
  const [queryParams, setQueryParams] = useState({});
  const baseUrl = window.location.origin || '';
  useEffect(() => {
    const getQueryParams = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const params = {
        url: searchParams.get("back")
      };

      // Set the query parameters in the state
      setQueryParams(params);
  console.log("params: ",params.url)
     
    };
    
    getQueryParams();
  }, []);
  return (
    <>
      <div className="container-fluid qwert" style={{ color: "white" }}>
        <div className="row w-100">
          <section className="w-100 main-section">
            <div className="main-page-con m-auto">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="thank-you-div">
                  
                  <div className="thank-you-card w-100 mt-2">
                  <img src={abc} alt="" width={100} height={100} />
                    <p>
                      Payment Failed!.
                    </p>
                    <a href={`${baseUrl}${queryParams.url || ''}`} className="btn btn_event">Try again! </a>
                  </div>
                  
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>{" "}
    </>
  );
}

export default PaymentFailed;
