import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StepTwo = ({ otp, setOtp, handleVerifyOtp, isLoading }) => {
  const navigate = useNavigate();
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  return (
    <section className="main-bdy">
      <div className="step">
        <h2>
          {" "}
          <ArrowBack
            onClick={() =>     navigate(window.location.pathname)}
            style={{ display: "flex" }}
          />
          Enter OTP
        </h2>
        <input
          type="text"
          name="otp"
          value={otp}
          onChange={handleOtpChange}
          placeholder="Enter OTP"
        />

        {isLoading ? (
          <button>Verifying OTP..</button>
        ) : (
          <button onClick={handleVerifyOtp}>Submit</button>
        )}
      </div>
    </section>
  );
};

export default StepTwo;
