import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
import AppA from './components/AppA/AppA';
import AppB from './components/AppB/AppB';
import ThankYou from "./components/ThankYou/ThankYou";
import PaymentFailed from "./components/PaymentFailed/PaymentFailed";
import StepThree from './components/AppB/StepThree';
import { Toaster } from 'react-hot-toast';
// import StepForm from './components/AppB/StepForm';

function App() {
  return (
    <Router>
      <Toaster />

      <Routes>
        {/* Define route for AppA */}
        <Route  path="/us" element={<AppA />} />

        <Route exact path="/" element={<AppA />} />
        <Route  path="/survey-form" element={<AppA />} />
        <Route  path="/us/thank-you" element={<ThankYou isUs={true} />} />
        <Route  path="/thank-you" element={<ThankYou />} />
        <Route  path="/payment-failure" element={<PaymentFailed />} />

        <Route  path="/response/:slug" element={<AppB />} />
        <Route  path="/us/response/:slug" element={<AppB />} />
        <Route  path="/response/checkout/:slug" element={<StepThree />} />

        <Route
          
          path="/us/response/checkout/:slug"
          element={<StepThree />}
        />
      </Routes>
    </Router>
  );
}

export default App;
